import React, { useState, useEffect } from "react";
import { Table, Card, Space } from "lib";
import {
  Button,
  Form,
  message,
  Modal,
  TablePaginationConfig,
  Switch,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Layout } from "layout";
import {
  LanguageClient,
  LanguageDto,
  SupportedLanguageClient,
  UpdateSupportedLanguageCommand,
} from "@api";
import { showError } from "@action";
import { FormattedMessage } from "react-intl";
import LanguageMenu from "@components/Admin/Language/LanguageMenu";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { navigate } from "gatsby";
import GeneralFormModal from "@components/GeneralFormModal";

const defaultOrderBy = "id";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Page = () => {
  const auth = new SubpageAuth(SECTION.Admin, "Language", "Language");
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const supportedLanguageClient = new SupportedLanguageClient();
  const languageClient = new LanguageClient();

  const [state, setState] = useState({
    items: [] as LanguageDto[],
    pagination: defaultPagination,
    loading: false,
  });

  const [languageOptionList, setLanguageOptionList] = useState([] as any[]);

  // const [keywords, setKeywords] = useState(
  //   undefined as string | undefined | null
  // );

  async function fetchItems(
    params: any = { pagination: state.pagination }
    // _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "Language")) return;

    setState({
      ...state,
      loading: true,
    });
    try {
      const res =
        await supportedLanguageClient.getSupportedLanguagesWithPagination(
          undefined,
          // _keywords ?? keywords,
          params.pagination.current,
          params.pagination.pageSize,
          capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
          (params.sortOrder ?? defaultSortOrder) == "ascend"
        );

      setState({
        ...state,
        items: res.items as LanguageDto[],
        pagination: {
          ...params.pagination,
          total: res.totalCount,
        },
        loading: false,
      });
    } catch (err) {
      setState({
        ...state,
        loading: false,
      });
      showError(err);
    }
  }

  async function fetchLanguageOptionList() {
    const res = await languageClient.getLanguages();

    const optionList = res
      .filter((o) => o.enable == false)
      .map((o) => {
        return { value: o.id, label: o.displayName };
      });

    setLanguageOptionList(optionList);
  }

  async function handleAddContent(data: any) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticCreatingMessage" />
    );
    setCreateModalVisible(false);

    try {
      await supportedLanguageClient.create(data);
      messageApi.success(<FormattedMessage id="StaticCreateSuccessMessage" />);
      await fetchItems();
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  function handleCancelAddContent() {
    setCreateModalVisible(false);
  }

  async function handleEditLanguage(id: string, isEnabled: boolean) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUpdatingMessage" />
    );
    try {
      await supportedLanguageClient.update(
        new UpdateSupportedLanguageCommand({
          id,
          enable: isEnabled,
        })
      );
      messageApi.success(<FormattedMessage id="StaticUpdateSuccessMessage" />);
      await fetchItems();
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  // async function handleKeywordsSearch(values: any) {
  //   setKeywords(values.keywords);
  //   await fetchItems(undefined, values.keywords);
  // }

  const columns: any = [
    {
      // title: "ID",
      title: <FormattedMessage id="LanguageTableHeaderId" />,
      dataIndex: "id",
      key: "id",
      sorter: true,
      defaultSortOrder,
    },
    {
      // title: "display Name",
      title: <FormattedMessage id="LanguageTableHeaderDisplayName" />,
      dataIndex: "displayName",
      key: "displayName",
      sorter: true,
    },
    {
      // title: "Is Enabled",
      title: <FormattedMessage id="LanguageTableHeaderIsEnabled" />,
      dataIndex: "enable",
      key: "enable",
      render: (b: boolean, record: LanguageDto) =>
        auth.can(ACTION.Edit, "Language") ? (
          <Switch
            checked={b}
            onChange={(checked) =>
              handleEditLanguage(record.id as string, checked)
            }
          ></Switch>
        ) : null,
    },
  ];

  useEffect(() => {
    fetchItems();
    fetchLanguageOptionList();
  }, []);

  return (
    <Layout title="LanguageLanguageNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <LanguageMenu />
          <Space justify="space-between">
            <Space justify="flex-start">
              <Form
                form={form}
                name="horizontal_login"
                layout="inline"
                // onFinish={handleKeywordsSearch}
              >
                {/* <Form.Item name="keywords">
                  <Input placeholder={StaticKeywords} />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button type="primary" htmlType="submit">
                      <FormattedMessage id="StaticSearchLabel" />
                    </Button>
                  )}
                </Form.Item> */}
              </Form>
            </Space>
            <Space wrap justify="flex-end">
              {auth.can(ACTION.Create, "Language") ? (
                <Button
                  type="primary"
                  onClick={() => {
                    setCreateModalVisible(true);
                  }}
                >
                  <Space>
                    <PlusOutlined />
                    <FormattedMessage id="LanguageLanguageContentAddButton" />
                  </Space>
                </Button>
              ) : null}
            </Space>
          </Space>
          <Table
            bordered
            dataSource={[...state.items]}
            columns={columns}
            rowKey={(r) => r.id}
            pagination={state.pagination}
            loading={state.loading}
            onChange={handleTableChange}
          />
        </Space>
      </Card>
      <GeneralFormModal
        visible={createModalVisible}
        handleAction={handleAddContent}
        handleCancelAction={handleCancelAddContent}
        titleId={"LanguageLanguageModalAddTitle"}
        editableFields={[
          {
            name: "id",
            labelId: "LanguageLanguageStaticSelectLabel",
            type: "select",
            options: languageOptionList,
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage id="LanguageLanguageStaticMissingSelectRule" />
                ),
              },
            ],
          },
        ]}
      />
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default Page;
